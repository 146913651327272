.referral-card {
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.03);
  background-color: #fff;
}

.mt-36 {
  margin-top: 36px;
}

.max-w-160 {
  max-width: 160px;
}

.gap-24 {
  gap: 24px;
}

.bg-line {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.15);
}

.referral-code-container {
  height: 211px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.referral .app-button {
  height: 40px;
  font-size: 12px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.referral-codes-title {
  padding-bottom: 24px;
}


.referral-address-input-box {
  margin: 50px 0;
}

.referral-address-input-box > input {
  width: 100%;
  height: 40px;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.referral-invites-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.referree-item {
  width: 290px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.border-r {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.info-item {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .referral {
    margin: 24px 0;
    width: calc(100vw - 32px) !important;
  }

  .referral .m-flex-col {
    flex-direction: column;
  }

  .referral-card {
    padding: 16px;
  }

  .referree-item {
    width: 100%;
  }

  .m-hide {
    display: none;
  }

  .referral-address-input-box {
    margin: 24px 0;
  }
}
