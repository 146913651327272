.app-pool-overview-card {
  width: 100%;
  margin-top: 40px;
  padding: 23px 30px;
}

.app-pool-overview-card-title {
  color: rgba(0, 0, 0, 1);
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.app-pool-overview-card-title>img {
  margin-right: 12px;
}

.app-pool-overview-content>div:first-child {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 5px;
}

.app-pool-overview-content>div:last-child {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}

.app-pool-overview-more {
  position: relative;
  height: 100%;
  opacity: 0;
}

.app-pool-overview-more>img {
  position: absolute;
  top: 10px;
  right: 0;
  margin: -20px;
  z-index: 100;
  transform: rotate(90deg);
}

.app-pool-overview-more>div {
  position: absolute;
  bottom: -40px;
  right: 15px;
  width: 1px;
  height: 120px;
  background: RGBA(0, 0, 0, 0.15);
  z-index: 10;
}

.app-pool-hint {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  margin: 20px 0;
}

.app-pool-hint>a {
  color: RGB(0, 122, 240);
  text-decoration: underline;
}

.app-pool-trade-desc {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100vw - 426px);
  max-width: 920px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.app-desc-wrap {
  padding: 0px 24px;
}

.app-pool-trade-desc h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.app-pool-trade-desc .desc-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.app-pool-trade-desc p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  margin: 24px 0;
}

.app-pool-trade-action {
  padding: 16px;
  width: 360px;
}

.app-pool-trade-action .app-token-input {
  padding: 8px;
}

.app-pool-trade-action .app-token-input-balance {
  font-size: 12px;
  line-height: 16px;
}

.app-pool-trade-action .app-token-input-input {
  font-size: 20px;
}

.app-pool-trade-action .app-pool-action-input-title {
  display: none;
}

.app-pool-trade-action .app-crypto-dropdown-header {
  font-size: 20px;
}

.app-pool-action-tab {
  margin-bottom: 24px;
  height: 48px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-pool-action-tab-item {
  height: 44px;
  line-height: 44px;
  width: calc(50% - 2px);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.app-pool-action-tab-item-active {
  height: 44px;
  color: #007AF0;
  background-color: #fff;
  border-radius: 9px;
}

.app-pool-action-input-title {
  margin: 20px 0 10px 0;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.app-pool-swiper-actions {
  height: 64px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.app-pool-action-hint {
  color: rgba(0, 0, 0, 0.495821);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  margin-top: 16px;
}

.app-pool-action-hint>div:last-child {
  color: RGB(0, 122, 240);
  text-decoration: underline;
  cursor: pointer;
}

.app-pool-action-button {
  height: 60px;
  margin-top: 24px;
}

.app-stake-claim {
  width: auto;
  padding: 4px 8px;
  height: 32px;
  font-size: 12px;
  margin-left: 8px;
}

.app-pool-title {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  margin-left: 8px;
}

.app-pool-footer {
  width: 100%;
}

.app-stake-container {
  width: 100%;
  height: 300px;
  padding: 24px;
  display: flex;
  position: relative;
  margin-bottom: 24px;
  border: 3px solid transparent;
  border-radius: 16px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(to right, #13f287, #ffc90b, #2482ff, #2485fc);
}

.app-stake-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-stake-info .app-stake-title {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #F1D789;
}

.app-stake-text {
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  max-width: 500px;
}

.app-stake-portfolio {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: #F1D789;
}

.app-stake-values {
  display: flex;
  margin-top: 24px;
  color: #fff;
}

.app-stake-divider {
  width: 1px;
  height: 40px;
  margin: 0 24px;
  background-color: rgba(255, 255, 255, 0.5);
}

.app-stake-value-title {
  font-size: 12px;
  zoom: 0.8;
}

.app-stake-value {
  font-size: 20px;
  font-weight: 700;
  margin-top: 4px;
}

.app-stake-wrap {
  width: 328px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-stake-switch-group {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background-color: #2f2f2f;
}

.app-stake-switch {
  width: calc(50% - 2px);
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 9px;
  color: #6B6D79;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.app-stake-switch.active {
  color: #fff;
  background-color: #000;
}

.app-stake-input-container {
  margin-top: 24px;
  padding: 8px;
  border-radius: 8px;
  background-color: #2F2F2F;
}

.app-stake-input-title {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}

.app-stake-input-wrap {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.app-stake-input-wrap input {
  width: 214px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  border: none;
  background-color: transparent;
}

.max-btn {
  margin-right: 8px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  color: #007AF0;
  border-radius: 10px;
  border: 1px solid #007AF0;
  cursor: pointer;
}

.max-btn:active {
  color: rgba(0, 122, 240, 0.8);
  border: 1px solid rgba(0, 122, 240, 0.8);
}

.app-pool-overview {
  margin-top: 24px;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
}

.app-pool-overview .app-card-white {
  box-shadow: none;
}

.app-pool-overview .app-table-row .app-table-cell:first-child {
  padding-left: 8px;
}

.slide-item .item-img {
  max-height: 270px;
}

.chart-time {
  font-size: 10px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.7);
  background-color: #f0f0f0;
  cursor: pointer;
}

.chart-time.active {
  color: #fff;
  background-color: #007AF0;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media screen and (max-width: 1024px) {
  .app-pool-overview-card .detail-section {
    width: 50%;
  }

  .app-pool-overview-card .detail-section .detail-section-title {
    margin-top: 8px;
    margin-bottom: 0;
  }

  .pool-container .app-table {
    grid-template: auto 16px repeat(2, 60px) / 8px repeat(7, auto) 20px !important;
  }

  .pool-container .app-card-white {
    flex-wrap: wrap;
    flex-direction: row !important;
  }

  .pool-container .app-pool-overview-card-title {
    width: 100%;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .pool-container .app-pool-overview-card-title::after {
    content: ' ';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .pool-container .app-pool-overview-card {
    padding: 16px;
  }

  .pool-container .app-pool-overview-more {
    display: none;
  }

  .pool-container .app-pool-hint {
    font-size: 12px;
    zoom: 0.78;
  }

  .pool-container .app-pool-trade {
    display: block;
  }

  .pool-container .app-pool-trade-desc {
    padding: 16px;
    margin-bottom: 16px;
    max-width: 100%;
  }

  .pool-container .app-pool-trade-desc p {
    width: auto;
    font-size: 12px;
    margin: 16px 0;
  }

  .pool-container .app-table-row {
    font-size: 12px;
    zoom: 0.83;
    text-align: center;
  }

  .pool-container .app-table-row div:nth-child(4) {
    min-width: 70px;
  }

  .pool-container .app-table-row div:nth-child(5) {
    min-width: 60px;
  }

  .pool-container .app-table-row div:nth-child(6) {
    min-width: 40px;
  }

  .pool-container .app-table-cell {
    min-width: 50px;
    height: 20px;
  }

  .app-stake-container {
    height: auto;
    flex-direction: column;
  }

  .app-stake-portfolio,
  .app-stake-wrap {
    margin-top: 32px;
  }

  .app-stake-wrap {
    width: auto;
  }

  .app-stake-values {
    flex-wrap: wrap;
  }

  .app-stake-value-item {
    width: 50%;
  }

  .app-stake-divider {
    display: none;
  }

  .app-stake-input-wrap input {
    width: calc(100vw - 200px);
  }

  .app-pool-trade-action {
    width: calc(100vw - 38px);
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  }

  .app-pool-trade-desc {
    border-radius: 16px;
    border-right: none;
    background-color: #fff;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  }

  .app-desc-wrap {
    padding: 0;
  }

  .app-pool-swiper-actions,
  .app-desc-wrap .swiper-button-prev,
  .app-desc-wrap .swiper-button-next {
    display: none;
  }

  .slide-item {
    flex-direction: column-reverse;
  }

  .slide-item .item-img {
    margin-top: 16px;
    max-width: calc(100vw - 100px) !important;
  }

  .app-desc-wrap .swiper-pagination {
    left: 0 !important;
    width: 100% !important;
    bottom: 16px !important;
  }

  .app-pool-trade-container {
    box-shadow: none;
    background-color: initial;
    border-radius: 0;
  }

  .alp-price .text-20 {
    font-size: 16px;
  }

  .alp-price .mr-32 {
    margin-right: 16px;
  }
}

@media screen and (max-width: 1300px) {
  .slide-item .item-img {
    max-width: 20vw;
  }
}