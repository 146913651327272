.ab-content {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 30px;
}

.ab-content>div:nth-child(3) {
  grid-column: 1 / 3;
}

.ab-content>div {
  padding: 36px 0 0 65px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.ab-content>div>img {
  position: absolute;
  top: 160px;
  left: 40px;
  opacity: 0;
}

.ab-content>div>span {
  display: block;
  max-width: 434px;
  color: rgba(0, 0, 0, 0.40);
}

.ab-content>div:last-child>img:last-child {
  display: block;
  color: rgba(0, 0, 0, 0.40);
  position: absolute;
  top: 30px;
  left: 600px;
  opacity: 1;
}

@keyframes ab-fade-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ab-animate-fade-in {
  animation: ab-fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.ab-animate-fade-in-2 {
  animation: ab-fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.ab-animate-fade-in-3 {
  animation: ab-fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}