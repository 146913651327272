.swiper {
  position: initial !important;
}

.swiper-pagination {
  bottom: 24px !important;
  width: 100px !important;
  left: initial !important;
  right: 30px !important;
}

.swiper-pagination-bullet {
  transition: all 0.2s ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 24px !important;
  border-radius: 8px !important;
}

.swiper-button-prev {
  height: 32px !important;
  top: initial !important;
  left: 20px !important;
  bottom: 18px !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.swiper-button-next {
  height: 32px !important;
  top: initial !important;
  left: 46px !important;
  bottom: 18px !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 12px !important;
  color: #000;
}
