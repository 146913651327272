.bc-content-card {
  height: 560px;
  padding: 62px 0 0 66px;
  position: relative;
  overflow: hidden;
}

.bc-content-card>h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.bc-content-card>span {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.40);
  display: block;
  max-width: 666px;
}

.bc-content-button {
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 31px;
  width: 340px;
  height: 60px;
  background-color: #139EC7;
  transition: background-color 1s ease-in-out;
  border-radius: 10px;
  margin-top: 120px;
  display: block;
  opacity: 0;
}

.bc-content-button:hover {
  background-color: #24CAE1;
  box-shadow: 0px 4px 14px 0px rgba(36,202,225,0.4);
}

.bc-content-big-pic {
  width: 500px;
  height: 560px;
  background: linear-gradient(215deg, #F6F6F6 0%, #B7B7B7 100%);  
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  opacity: 0;
}

.bc-content-big-pic>img {
  width: auto;
  height: 700px;
  top: 30px;
  right: -146px;
  position: absolute;
}

.bc-content-small-pic {
  position: absolute;
  top: 439px;
  left: 627px;
  opacity: 0;
}

.bc-animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.bc-animate-fade-in-2 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.bc-animate-fade-in-3 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}