.footer-content {
  padding: 24px 0 19px 0;
}

.footer-social-media {
  width: 30px;
  height: 30px;
  margin-right: 30px;
  display: block;
  transition: background-image 0.2s ease-in-out;
}

.footer-social-media:last-of-type {
  margin-right: 0;
}

/* .footer-social-media:first-of-type {
  background-image: url('../../assets/telegram.svg');
} */

.footer-social-media:nth-of-type(1) {
  background-image: url('../../assets/twitter.svg');
}

.footer-social-media:nth-of-type(1):hover {
  background-image: url('../../assets/twitter-hover.svg');
}

.footer-social-media:nth-of-type(2) {
  background-image: url('../../assets/email.svg');
}

.footer-social-media:nth-of-type(2):hover {
  background-image: url('../../assets/email-hover.svg');
}

.footer-social-media:nth-of-type(3) {
  background-image: url('../../assets/discord.svg');
}

.footer-social-media:nth-of-type(3):hover {
  background-image: url('../../assets/discord-hover.svg');
}