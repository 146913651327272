.app-footer-container {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 48px;
  padding-bottom: 24px;
  display: flex;
}

.app-footer {
  max-width: 1280px;
  padding: 8px 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.03);
}

.app-footer.pool-footer {
  margin-top: 88px;
}

.media-link a {
  width: 48px;
  height: 48px;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F0F0F0;
}

.guide-link a {
  margin: 0 8px;
  padding: 8px 12px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F0F0F0;
}

.guide-link a span {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 8px;
}
