.app-modal-card-header {
  margin-bottom: 28px;
}

.app-modal-card-header-title {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 700;
}

.app-modal-card-header-title>img {
  margin-right: 15px;
}

.app-modal-card-header-close>img {
  margin: -20px;
  cursor: pointer;
}