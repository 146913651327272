.app-pos-close-main {
  width: 430px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.app-pos-close-card {
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 430px;
  padding: 20px;
}

.app-pos-close-token-input {
  margin-bottom: 20px;
}

.app-pos-close-info-list {
  margin-bottom: 20px;
}

.app-trade-action-tab {
  margin-bottom: 20px;
}