.app-swap-confirm-card {
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 430px;
  padding: 20px;
}

.app-swap-confirm-info-list {
  margin-bottom: 20px;
}

.app-swap-confirm-token-info {
  margin-bottom: 20px;
}

.app-swap-confirm-token-info-item {
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  width: calc(50% - 5px);
  padding: 10px;
}

.app-swap-confirm-token-info-item-title>div:first-child {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.app-swap-confirm-token-info-item-title>div:last-child {
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 700;
  text-align: right;
  line-height: 20px;
}

.app-swap-confirm-token-info-item-value {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 700;
  text-align: right;
  line-height: 20px;
  width: 100%;
  margin-top: 22px;
}

.app-swap-confirm-warning {
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  padding: 10px;
  margin-bottom: 20px;
}

.app-swap-confirm-warning-title {
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.app-swap-confirm-warning-content {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}