.app-token-amount {
  width: 100px;
  height: 60px;
  padding: 10px;
  border-radius: 10px;
}

.app-token-amount-amount {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  width: 80px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  position: relative;
  text-align: left;
}

.app-token-amount-symbol {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}