.feature-content {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 30px;
}

.feature-content>div {
  padding: 36px 0 0 65px;
  height: 360px;
  position: relative;
}

.feature-content>div>h3 {
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 32px;
}

.feature-content>div>span {
  font-size: 24px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.40);
  line-height: 32px;
  display: block;
  max-width: 380px;
}

.feature-content>div>img {
  position: absolute;
  right: 65px;
  bottom: 36px;
  opacity: 0;
}

.feature-animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.feature-animate-fade-in-2 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.feature-animate-fade-in-3 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.feature-animate-fade-in-4 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}