.header-card {
  height: 90px;
  padding: 0 30px;
}

.header-card-remove {
  border: 0;
  width: 90%;
  max-width: 1280px;
  box-shadow: none !important;
}

.header>img:first-child {
  width: 128px;
  height: 42px;
}

.header-sidebar>span {
  margin-right: 50px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;
}

.trade-icon {
  height: 50px;
  width: 115px;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 700;
}