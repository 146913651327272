.app-token-input {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  padding: 13px 10px;
  margin-bottom: 16px;
  width: 100%;
}
.app-token-input:first-child {
  margin-top: 16px;
}
.app-token-input>div:first-child {
  margin-bottom: 16px;
}

.app-token-input-usd {
  color: rgba(0, 0, 0, 0.495821);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.app-token-input-input {
  color: rgba(0, 0, 0, 0.7);
  font-size: 25px;
  font-weight: 700;
  line-height: 20px;
  border: 0;
  background: inherit;
  width: 100%;
  flex-shrink: 1;
}

.app-token-input-input:focus {
  outline: none;
}


.app-token-input-token-text {
  color: rgba(0, 0, 0, 1);
  font-size: 25px;
  font-weight: 700;
  line-height: 20px;
}

.app-token-input-balance {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  line-height: 20px;
}

.app-token-input-leverage {
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  width: 100px;
  height: 20px;
  opacity: 1;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.app-token-input-max {
  border-radius: 10px;
  border: 1px solid rgba(0, 122, 240, 0.5);
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  color: rgba(0, 122, 240, 1);
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  width: 40px;
  height: 20px;
  cursor: pointer;
  z-index: 10;
  margin-right: 10px;
}

.app-token-input-content {
  width: 100%;
}

.mb-8-important {
  margin-bottom:8px!important;
}