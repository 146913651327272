.app-pnl-amount {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.app-pnl-percentage {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  zoom:0.9;
}