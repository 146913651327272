.intro-card {
  padding: 55px 66px 68px 64px;
  position: relative;
}

.intro-logo {
  background-image: url('../../assets/intro-logo.svg');
  width: 210px;
  height: 210px;
  position: absolute;
  z-index: 1;
  top: -105px;
  right: 26px;
  opacity: 0;
}

.intro-card>p {
  max-width: 566px;
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.40);
  line-height: 32px;
  margin-bottom: 131px;
}

.intro-card>p>span {
  color: rgba(0, 0, 0, 1);
}

.intro-card-content {
  width: 100%;
}

.intro-animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.intro-animate-fade-in-2 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.intro-animate-fade-in-3 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.intro-animate-fade-in-4 {
  animation: fade-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}

.intro-card-content>div {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 21px;
  overflow: hidden;
  margin: 0 10px;
  width: calc(100%/3 - 20px);
  opacity: 0;
}

.intro-card-content>div>div:first-child {
  width: 100%;
  height: 205px;
  background-size: cover;
  margin-bottom: 37px;
}

.intro-card-content>div:first-child>div:first-child {
  background-image: url('../../assets/token-swap.svg');
}

.intro-card-content>div:nth-child(2)>div:first-child {
  background-image: url('../../assets/leveraged-trading.svg');
}

.intro-card-content>div:nth-child(3)>div:first-child {
  background-image: url('../../assets/provide-liquidity.svg');
}

.comming-soon-icon {
  width: 154px;
  height: 41px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.3);
  line-height: 20px;
  margin: 30px 0 25px 0;
}