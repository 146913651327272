body * {
  box-sizing: border-box;
  flex-shrink: 0;
}

body {
  font-family: Red Hat Display, PingFangSC-Regular, Roboto, Helvetica Neue,
    Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  margin: 0;
  scroll-behavior: smooth;
  background: #F6F6F6;
}
html,
body {
  height: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:focus-visible {
  outline: none;
}

button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}

.ReactModal__Overlay {
  z-index: 100;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.text-left {
  text-align: left;
}

.card-max-white {
  width: 100%;
  background: #f6f6f6;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1),
    0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.card-max-black {
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.3),
    0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.app-card-white {
  width: 100%;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.global-container {
  width: 100%;
  /* min-width: 1280px; */
}

.section-container {
  width: 95%;
  max-width: 1280px;
}

.wkit-address-select__right-arrow {
  display: none;
}

.header-img {
  height: 42px;
  width: 129px;
}
.is-pc {
  display: flex;
}
.is-mobile {
  display: none;
}

.wkit-disconnect-button__container {
  border: 1px solid rgba(0,0,0,0.1) !important;
  bottom: -42px !important;
  z-index: 999;
}

.wkit-disconnect-button__container .wkit-disconnect-button {
  padding: 10px 14px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.react-hint__content {
  max-width: 600px;
}

.min-w-100 {
  min-width: 100px;
}
