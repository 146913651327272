.app-trade-overview-card {
  margin-top: 40px;
  padding: 23px 30px;
  width: 100%;
}

.app-trade-overview-card > div:first-child > img:first-child {
  margin-right: 12px;
}

.app-trade-overview-card > div:first-child > span {
  font-size: 25px;
  font-weight: 700;
  color: #000000;
  line-height: 30px;
  margin-right: 15px;
}

.trade-number {
  width: 430px;
}

.app-trade-price-node > div {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 14px;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-left: 10px;
  padding: 3px 10px;
}

.app-trade-rollover {
  margin-right: 33px;
}

.app-trade-main {
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 896px 360px;
  grid-template-areas: a, b c, d e, e;
}

.app-trade-main > div:first-child {
  flex: 2;
}

.app-trade-main > div:last-child {
  /* margin-left: 20px; */
  flex: 1;
  grid-column: 2;
}

.app-trade-table {
  margin: 16px 0 20px 0;
}

.app-trade-info-tab {
  margin-top: 20px;
  font-size: 11px;
  font-weight: 700;
}

.app-trade-info-tab > div {
  margin-right: 20px;
  border-radius: 10px;
  padding: 9px 10px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.white-div {
  width: 25rem;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  margin-left: 48px;
  padding: 12px;
}
.app-trade-table-symbol {
  align-items: flex-start;
}
.app-trade-table-symbol-name {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  text-align: left;
}

.app-trade-table-symbol-leverage {
  border-radius: 16px;
  padding: 4px 6px;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  font-weight: 500;
  text-align: center;
  line-height: 12px;
}

.app-trade-detail-hide {
  opacity: 0;
}
.app-trade-table-symbol-image {
  width: 12px;
  height: 12px;
}
.app-trade-table-symbol-image-left {
  position: absolute;
  left: 8px;
}

.app-trade-position-table.app-table,
.app-trade-position-table .app-table-row-header {
  font-size: 12px;
}

.trade-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  width: 100%;
  text-align: left;
  line-height: 1.5;
}

.table-btn {
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.adjust-btn {
  background: url('../assets/adjust.svg') no-repeat center;
  background-size: cover;
}

.adjust-btn:hover {
  background: url('../assets/adjust-active.svg') no-repeat center;
  background-size: cover;
}

.close-btn {
  background: url('../assets/cancel.svg') no-repeat center;
  background-size: cover;
}

.close-btn:hover {
  background: url('../assets/cancel-active.svg') no-repeat center;
  background-size: cover;
}

.share-btn {
  background: url('../assets/share.svg') no-repeat center;
  background-size: cover;
}

.share-btn:hover {
  background: url('../assets/share-active.svg') no-repeat center;
  background-size: cover;
}

.reduce-btn {
  background: url('../assets/reduce.svg') no-repeat center;
  background-size: cover;
}

.reduce-btn:hover {
  background: url('../assets/reduce-active.svg') no-repeat center;
  background-size: cover;
}
.inter-network-btn {
  background: url('../assets/inter-network.svg') no-repeat center;
  background-size: cover;
}
.inter-network-btn:hover {
  background: url('../assets/inter-network-active.svg') no-repeat center;
  background-size: cover;
}

.app-position-table-mobile {
  padding: 16px;
}

.app-position-table-mobile .position-item {
  padding-top: 16px;
  font-weight: 500;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.app-position-table-mobile .table-title {
  color:rgba(0, 0, 0, .5);
}

.app-position-table-mobile .table-content {
  color:rgba(0, 0, 0, .7);
}

.app-position-table-mobile .position-symbol-image {
  height: 16px;
}

.app-position-table-mobile .position-symbol-image img {
  width: 16px;
  height: 16px;
}

.app-position-table-mobile .symbol-image-left {
  position: relative;
  z-index: 20;
}

.app-position-table-mobile .symbol-image-right {
  margin-left: -6px;
}

.app-position-table-mobile .position-symbol-name {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
}

.app-position-table-mobile .position-symbol-leverage {
  padding: 2px 0;
  font-size: 12px;
  zoom: 0.8;
  min-width: 30px;
  text-align: center;
  border-radius: 6px;
  color: #fff;
}

.app-position-table-mobile .position-content {
  margin-top: 16px;
  font-size: 12px;
  zoom: 0.8;
  display: flex;
  flex-wrap: wrap;
}

.app-position-table-mobile .position-content-item {
  width: 28%;
  margin-bottom: 16px;
}

.app-position-table-mobile .position-content-item.text-right {
  text-align: right;
}

.app-position-table-mobile .position-content-item:nth-child(1),
.app-position-table-mobile .position-content-item:nth-child(4) {
  width: 44%;
}

.app-position-table-mobile .position-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-position-table-mobile .position-actions span {
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-radius: 6px;
  background-color: #007AF0;
}

.app-position-table-mobile .position-actions span:active {
  background-color: rgba(0, 122, 240, 0.7);
}

.notice-card {
  margin-left: 32px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, .15);
}

.notice-card div {
  margin-left: 16px;
  font-size: 12px;
  zoom: 0.8;
  width: calc(100% - 48px);
}

.notice-card-mobile {
  margin-top: 24px;
  flex-direction: column;
}

.notice-card-mobile .sui-grant {
  width: 130px;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  color: #fff;
  background: #56ABFF;
}

.notice-card-mobile .sui-grant span {
  margin-left: 8px;
}

.notice-card-mobile .app-card-white {
  margin-top: 16px;
  padding: 16px;
}

.notice-card-mobile .notice-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
  color: rgba(0, 0, 0, .7);
}

.notice-info a {
  color:#007AF0;
}

.process-content {
  width: 66px;
  height: 60px;
  padding: 10px;
  border-radius: 10px;
}

.process {
  width: 2px;
  height: 8px;
  margin-right: 2px;
  border-radius: 2px;
  background: rgba(118, 118, 128, 0.12);
}
.process:last-child {
  margin-right: 0px;
}


