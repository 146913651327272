.follow-main {
  width: 100vw;
  height: 100vh;
}

.follow-main-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}

.follow-card {
  background: #F6F6F6;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 660px;
  height: 514px;
}

.follow-card-logo-section {
  width: 100%;
  margin-top: 76px;
}

.follow-card-logo-section>div:first-child {
  width: 205px;
  height: 100px;
  background: linear-gradient(90deg, #FFFFFF 0%, #747DF7 100%);
  border-radius: 0px 50px 50px 0px;
}

.follow-card-logo-section>div:last-child {
  width: 205px;
  height: 100px;
  background: linear-gradient(270deg, #FFFFFF 0%, #747DF7 100%);
  border-radius: 50px 0px 0px 50px;
}

.follow-card-desc {
  margin: 57px 0 0 65px;
  width: 540px;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  line-height: 32px;
}

.follow-card-desc>span {
  display: block;
  color: rgba(0, 0, 0, 0.40);
}

.follow-card-button-group {
  margin: 54px 64px 0 64px;
}

.follow-card-button-group>a,.follow-card-button-group>div {
  display: block;
  width: 156px;
  height: 60px;
  background-size: cover;
  cursor: pointer;
}

.follow-card-button-group>a:first-child {
  background-image: url('../../assets/popup-discord-button.svg');
}

.follow-card-button-group>a:nth-child(2) {
  background-image: url('../../assets/popup-twitter-button.svg');
}

.follow-card-button-group>div:last-child {
  width: 158px;
  height: 62px;
  background-image: url('../../assets/popup-next-time-button.svg');
}