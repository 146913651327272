.header-section {
  margin-top: 20px;
  position: fixed;
}

.header-section-fixed {
  position: fixed;
  left: 0;
  margin-top: 0;
  z-index: 10;
  width: 100vw !important;
  max-width: 100vw !important;
  background-color: #F6F6F6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
}

.header-section-fixed-safari {
  width: 100vw !important;
  max-width: 100vw !important;
}

.main-block-section {
  margin-top: 216px;
  padding-bottom: 150px;
}

.intro-section {
  margin-bottom: 150px;
}

.ab-section {
  margin-bottom: 150px;
}

.feature-section {
  margin-bottom: 150px;
}

.bc-section {
  margin-bottom: 150px;
}

.footer-divide-line {
  border-top: 1px solid #EBE9E9;
  width: 100%;
}

.section-title {
  max-width: 776px;
  font-size: 36px;
  font-weight: 700;
  color: #000000;
  line-height: 48px;
  margin: 40px 0 50px 60px;
  opacity: 0;
}

.title-animate-fade-in {
  animation: fade-in 2s ease-in-out;
  animation-fill-mode: forwards;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* 100% of the viewport height */
  background-image: url('../assets/background.svg');
  background-size: cover; /* Change this value from 'cover' to 'contain' */
  background-repeat: no-repeat; /* Add this line to prevent the image from repeating */
  background-position: center top; /* Change this value to position the image at the top center */
  z-index: -1; /* To ensure the content is on top of the background */
  opacity: 0.5;
}

.bg-fade-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 100%);
}