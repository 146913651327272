.main-block-card {
  padding: 110px 0 80px 80px;
  position: relative;
  overflow: hidden;
}

.main-block-card>h1 {
  max-width: 628px;
  font-size: 85px;
  color: #FFFFFF;
  line-height: 95px;
  font-weight: 700;
  margin: 0;
}

.main-block-card>span {
  max-width: 587px;
  font-size: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  line-height: 40px;
  margin: 50px 0 110px 0;
}

.launch-icon {
  background-image: linear-gradient(90deg, #084C8A 0%, #1572CB 100%);
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 31px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.launch-icon::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(90deg, #0F61AE 0%, #1789F6 100%);
  z-index: -1;
  transition: opacity 1s linear;
  opacity: 0;
  border-radius: 10px;
}

.launch-icon:hover::before {
  opacity: 1;
}

.launch-icon:hover {
  box-shadow: 0px 4px 14px 0px rgba(19,116,209,0.4);
}

.main-block-shape {
  position: absolute;
  z-index: 1;
  width: 600px;
  height: 600px;
  top: 50px;
  right: 0;
}