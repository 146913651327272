.app-stats {
  color: rgba(0,0,0,1);
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 40px;
}

.app-stats-overview {
  padding: 23px 30px;
}

.app-stats-overview>div>img {
  margin-right: 12px;
}

.app-stats-overview>div {
  margin-right: 160px;
}

.app-stats-major {
  margin-top: 20px;
}

.app-stats-major>div {
  width: calc(50% - 10px);
  padding: 30px;
}

.app-stats-major>div>div {
  margin-bottom: 30px;
}

.app-stats-major>div>div:last-child {
  margin-bottom: 0;
}

.app-stats-major>div>div:last-child>div {
  margin-right: 160px;
}

.app-stats-major>div>div>img {
  margin-right: 12px;
}

.app-stats-subtitle {
color: rgba(0,0,0,1);
font-size: 18px;
font-weight: 700;
line-height: 25px;
margin: 30px 20px 20px 20px;
}