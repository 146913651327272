.detail-section-title {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  margin-bottom: 5px;
}

.detail-section-content {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.stake-apr {
  font-size: 12px;
  font-weight: 500;
  zoom: 0.8;
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 8px;
  color: #000;
  background-color: #F1D789;
}

.funding.detail-section {
  cursor: pointer;
}
.funding .detail-section-content, .pool-apr .detail-section-content {
  border-bottom: 1px dotted rgba(0, 0, 0, .4);
}