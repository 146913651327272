.app-crypto-dropdown-dropdown {
  position: relative;
  display: inline-block;
  user-select: none;
}

.app-crypto-dropdown-header {
  font-size: 25px;
  font-weight: 700;
  color: #000000;
  line-height: 20px;
  cursor: pointer;
}
.app-crypto-dropdown-header-active {
  transform: rotate(180deg);
}

.app-crypto-dropdown-arrow {
  margin: -20px -20px -20px -5px;
}

.app-crypto-dropdown-header > div {
  margin-left: 15px;
  height: 30px;
  width: 30px;
}

.app-crypto-dropdown-list {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 90;
  width: 182px;
  background: #ffffff;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
}

.app-crypto-dropdown-title {
  padding: 0 0 8px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.app-crypto-dropdown-item {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 12px 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.app-crypto-dropdown-item:hover {
  background-color: #f6f6f6;
}

.app-crypto-dropdown-image {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.app-crypto-dropdown-symbol {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  line-height: 25px;
}

.app-crypto-dropdown-name {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 15px;
}
.search {
  width: 100%;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background: #f9f9f9;
}
.search-img {
  width: 15px;
  height: 15px;
}
.search-input {
  width:85%;
  border:none;
  outline: none;
  background: transparent;
}