.app-table {
  display: grid;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  align-items: center;
  justify-items: start;
  padding: 0 0 20px 0;
}

.app-table-row {
  display: contents;
}

.app-table-row-header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.495821) !important;
  background-color: inherit !important;
}

.app-table-cell {
  padding: 15px 0;
  height: 20px;
  font-size: 12px;
  width: 100%;
  box-sizing: content-box;
}

.app-table-header-cell {
  padding: 10px 0;
  font-size: 12px;
  max-width: 100px;
}

.app-trade-history-table .app-table-row .app-table-header-cell:nth-child(3), .app-trade-history-table .app-table-row .app-table-cell:nth-child(3) {
  padding-left: 16px;
}

.app-trade-history-table .app-table-row .app-table-header-cell {
  max-width: 200px;
}