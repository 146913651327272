.app-info-list {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  padding: 10px;
}

.app-info-list-row {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 8px;
}

.app-info-list-row:last-child {
  margin-bottom: 0;
}

.app-info-list-row-title {
  color: rgba(0, 0, 0, 0.5);
}

.app-info-list-row-value {
  color: rgba(0, 0, 0, 1);
}