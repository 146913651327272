.app-button {
  border-radius: 10px;
  width: 100%;
  height: 60px;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  background-color: #007AF0;
}

.app-button[button-style="loading"] {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ECECEC;
}

.app-button[button-style="long"] {
  background-color: #0ECA82;
}

.app-button[button-style="short"] {
  background-color: #FA3C58;
}

.app-button[button-style="disabled"] {
  color: rgba(0, 0, 0, 0.5);
  background-color: #ECECEC;
}