input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: all .2s ease;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #007AF0;
    border-radius: 50%;
    width: 0;
    height: 0;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all .2s ease;
    transform-origin: center center;
    pointer-events: none;
  }

  &:checked::after {
    width: 8px;
    height: 8px;
    opacity: 1;
  }
}

