.app-leverage {
  width: 100%;
}

.app-leverage > div {
  width: 100%;
}

.app-leverage-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.app-leverage-input {
  border-radius: 6px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background: #fff;

  width: 44px;
  height: 24px;
  text-align: center;
  line-height: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}
.app-leverage-input > input {
  border: none;
  outline: none;
  max-width: 28px;
  text-align: right;
}
.app-leverage-num {
  margin-top: 10px;
}

.app-leverage-num > div {
  font-size: 10px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
}
