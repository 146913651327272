.share-modal {
  width: 430px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.share-modal-header {
  padding: 24px;
  margin-bottom: 0px !important;
}

.share-modal-body {
  border-top: 1px solid #e5e5e5;
}

.share-modal-container {
  padding: 24px;
}

.share-info-wrap {
  border-radius: 10px;
  border: 1px solid rgba(229, 229, 229, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.share-info-container {
  padding: 24px 24px 12px;
}

.share-long-short {
  width: 103px;
  height: 48px;
  flex-shrink: 0;
}

.share-long-short img {
  width: 100%;
  height: 100%;
}

.share-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #000;
}

.share-text.long {
  background-color: #0ECA82;
}

.share-text.short {
  background-color: #FA3C58;
}

.share-long {
  color: #0ECA82;
}

.share-short {
  color: #FA3C58;
}

.share-increase {
  margin-top: 32px;
  font-size: 40px;
  font-weight: 700;
}

.share-price {
  padding: 16px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.share-price-title {
  font-size: 12px;
  zoom: 0.83;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.share-price-info {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 700;
}

.qr-code-wrap {
  padding: 16px 24px;
}

.share-bottom {
  padding: 16px 24px;
  background-color: #007AF0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.share-modal-footer {
  padding: 24px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .share-modal {
    width: calc(100vw - 32px);
    margin: 0 auto;
    max-height: calc(100vh - 64px);
    overflow-y: auto;
  }

  .share-modal.ReactModal__Content {
    padding: 0;
  }

  .share-modal-header, .share-modal-container, .share-modal-footer {
    padding: 16px;
  }

  .share-info-container {
    padding: 12px 12px 8px;
  }

  .share-increase {
    font-size: 36px;
  }

  .share-cancel-btn {
    display: none;
  }
}