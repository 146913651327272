.app-header {
  margin-top: 30px;
  position: relative;
}
.app-header>div:first-child>img {
  margin-right: 40px;
}

.app-header>div:first-child>img:first-child {
  margin-right: 10px !important;
}

.app-header>div:first-child>img:nth-child(2) {
  margin: 0 40px -4px 0;
}

.app-header nav a {
  display: block;
  padding: 5px 20px;
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  line-height: 20px;
}

.app-header nav {
  padding: 0 8px;
  height: 44px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  background-color: rgba(255, 255, 255, 0.75);
}

.app-header .sui-grant {
  width: 130px;
  margin-left: 16px;
  border-radius: 16px;
  background-color: #56ABFF;
  display: flex;
  align-items: center;
}

.app-header .sui-grant span {
  font-size: 14px;
  margin-left: 4px;
  color: #fff;
}

.app-header-page-selected {
  color: #FFFFFF !important;
  background: #007AF0;
  border-radius: 10px;
}

.wkit-button {
  width: auto !important;
  height: auto !important;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #007AF0 !important;
  line-height: 30px !important;
  padding: 5px 16px !important;
  margin: 0 !important;
}

:root {
  --wkit-button-width: auto !important;
  --wkit-border-radius: 10px !important;
  --wkit-font-family: Red Hat Display, PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei !important;
  --wkit-font-family-mono: Red Hat Display, PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei !important;
}

.wkit-connected-button {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
  width: auto !important;
  height: auto !important;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 44px 0px rgba(0,0,0,0.02) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(0,0,0,0.1) !important;
  padding: 5px 16px !important;
}

.wkit-connected-button__balance {
  display: none;
}

.wkit-connected-button__divider {
  display: none;
}

.wkit-address-select__address {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: rgba(0,0,0,0.5) !important;
  line-height: 30px !important;
}

.wkit-address-select__right-arrow {
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1) !important;
  border: 1px solid rgba(0,0,0,0.2) !important;
  border-radius: 10px !important;
  margin-left: 15px !important;
}

.wkit-disconnect-button {
  background-color: #FFFFFF !important;
}

.app-header-notification {
  opacity: 1;
}

.safe {
  width: 40px;
  height: 40px;
  margin-left: 12px;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;
}

.rpc-set-warp {
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.rpc-set {
  position: relative;
  margin: 8px;
  border-radius: 20px;
  background-color: #007AF0;
  cursor: pointer;
}

.rpc-text {
  padding-right: 8px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}

.rpc-set-container {
  position: absolute;
  right: 0;
  top: 58px;
  padding: 24px;
  width: 360px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  cursor: default;
  z-index: 100;
}

.current-rpc-node {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #f9f9f9;
}

.rpc-node-text {
  padding-bottom: 8px;
  color:rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.current-rpc-node-info {
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:rgba(0, 0, 0, 0.7);
}

.rpc-node-auto {
  width: 56px;
  text-align: center;
  padding: 2px 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #000;
}

.custom-rpc-node {
  padding: 8px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #f9f9f9;
}

.custom-rpc-node span {
  font-weight: 700;
}

.rpc-speed .status {
  display: inline-block;
  margin-left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.rpc-speed .green {
  background-color: #0ECA82;
}

.rpc-speed .orange {
  background-color: #FEAA28;
}

.rpc-speed .red {
  background-color: #FA3C58;
}

.rpc-node-list-container {
  margin-top: 24px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #f9f9f9;
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}

.rpc-node-list-title {
  padding-bottom: 8px;
}

.rpc-node-list-item {
  margin-top: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .safe {
    margin-left: 0 !important;
    padding: 4px;
    width: 24px;
    height: 24px;
  }

  .rpc-set-warp {
    box-shadow: none;
  }

  .rpc-text {
    font-size: 10px;
  }

  .rpc-set {
    margin: 0 8px !important;
    padding: 4px;
  }

  .rpc-set img, .safe img {
    width: 16px;
    height: 16px;
  }

  .rpc-set-container {
    top: 36px;
    right: 0;
  }

  .wkit-button {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
