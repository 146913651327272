.app-trade-action {
  padding: 20px;
  grid-row: 1/4;
}

.app-trade-action-tab {
  background:rgba(118, 118, 128, 0.12);
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 2px;
}

.app-trade-action-tab-item {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: 26px;
  height: 46px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
}

.app-trade-action-tab-item-active {
  border-radius: 10px;
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04),
    0px 3px 8px 0px rgba(0, 0, 0, 0.12);
}

.app-trade-action-tab-item-left-line::after {
  content: '';
  position: absolute;
  left: 0;
  width: 1px;
  height: 24px;
  background-color: rgba(60, 60, 67, 0.36);
}

.app-trade-action-tab-item-right-line::before {
  content: '';
  position: absolute;
  right: 0;
  width: 1px;
  height: 24px;
  background-color: rgba(60, 60, 67, 0.36);
}

.app-trade-action-tab2 {
  border-radius: 12px;
  height: 24px;
  padding: 2px;
  background: rgba(118, 118, 128, 0.12);
}

.app-trade-action-tab2-item {
  font-size: 10px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: 16px;
  padding: 2px 12px;
  cursor: pointer;
  border-radius: 10px;
}

.app-trade-action-tab2-item-active {
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  color: black;
  border-radius: 10px;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04),
    0px 3px 8px 0px rgba(0, 0, 0, 0.12);
}

.app-trade-action-tab2-item-disable {
  color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}

.app-trade-action-detail {
  width: 100%;
}

.app-trade-action-detail > div:first-child {
  width: 100%;
}

.app-trade-action-detail > div:nth-child(3) {
  width: 100%;
}

.app-trade-action-switch-token {
  width: 100%;
  position: relative;
}

.app-trade-action-switch-token-arrow {
  position: absolute;
  top: -30px;
  left: 50%;
  cursor: pointer;
  background-image: url('../../assets/switch-token.svg');
  background-size: contain;
  width: 80px;
  height: 80px;
  margin: -20px;
}

.app-trade-action-detail-msg {
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  width: 100%;
  padding: 10px;
  margin-top: 16px;
}

.app-trade-action-detail-msg > div {
  margin-bottom: 12px;
}

.app-trade-action-detail-msg > div:last-child {
  margin-bottom: 0;
}

.app-trade-action-detail-msg-title {
  color: rgba(0, 0, 0, 0.495821);
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.app-trade-action-detail-msg-value {
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.app-trade-action-detail-btn {
  width: 100%;
  height: 60px;
  margin-top: 16px;
}

.app-trade-action-setting {
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

.tradeAction-switch {
  width:26px;
  height:16px;
}
.tradeAction-switch-solid{
  width:14px;
  height:14px;
  fill: #fff;
  filter: drop-shadow(0px 1.548387050628662px 0.5161290168762207px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1.548387050628662px 4.129032135009766px rgba(0, 0, 0, 0.15));
}

.pyth-image {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
