@for $i from 1 through 30 {
  .rounded-#{$i} {
    border-radius: $i * 1px;
  }
  .z-#{$i} {
    z-index: $i;
  }
  .text-#{$i} {
    font-size: $i * 1px;
  }
  .w-#{$i} {
    width: $i * 1px;
  }
  .h-#{$i} {
    height: $i * 1px;
  }
  .mt-#{$i} {
    margin-top: $i * 1px;
  }
  .mr-#{$i} {
    margin-right: $i * 1px;
  }
  .mb-#{$i} {
    margin-bottom: $i * 1px;
  }
  .ml-#{$i} {
    margin-left: $i * 1px;
  }
  .pt-#{$i} {
    padding-top: $i * 1px;
  }
  .pr-#{$i} {
    padding-right: $i * 1px;
  }
  .pb-#{$i} {
    padding-bottom: $i * 1px;
  }
  .pl-#{$i} {
    padding-left: $i * 1px;
  }
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.flex {
  display: flex;
  &.flex-col {
    flex-direction: column;
  }
  &.items-center {
    align-items: center;
  }
  &.items-end {
    align-items: end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: end;
  }
  &.justify-start {
    justify-content: start;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.mr-32 {
  margin-right: 32px;
}

.mt-48 {
  margin-top: 48px;
}

.ease-in {
  transition: all 0.1s linear;
}
.flex-1 {
  flex: 1;
}
.grid {
  display: grid;
  /* grid-template: repeat(5, 1fr); */
}
.grid-template-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}
.grid-template-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
.grid-template-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
.gap-12 {
  gap: 12px;
}
.font-500 {
  font-weight: 500;
}
.font-700 {
  font-weight: 700;
}
.border {
  border: 1px rgba(0, 0, 0, 0.15) solid;
}
.reset-input {
  border: none;
  outline: none;
  width: 40px;
}
.text-center {
  text-align: center;
}
.border-left {
  border-left: 1px rgba(0, 0, 0, 0.15) solid;
}
.border-bottom {
  position: relative;
}
.border-bottom:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: calc(100% - 16px);
  height: 1px;
  background: rgba(0, 0, 0, 0.15);
}
.border-right {
  border-right: 1px rgba(0, 0, 0, 0.15) solid;
}
.min-width-45 {
  min-width: 45px;
}
.plus,
.minus {
  width: 11px;
  height: 11px;
  position: relative;
  display: inline-block;
}
.plus::before,
.minus::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 100%;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.7);
}
.plus::after {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 3px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.min-width-59 {
  min-width: 59px;
}

.animation {
  animation: FadeIn 0.1s linear;
}
.modal-animation {
  animation: modelIn 0.5s linear;
}
@keyframes FadeIn {
  0% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.925);
  }
  60% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(0.975);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes modelIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.app-crypto-dropdown-max-height {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 5px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background: #efefef;

  /* 滚动条里面小方块 */
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
}

.app-pair-dropdown_item:last-child,
.app-crypto-dropdown-item:last-child {
  margin-bottom: 0 !important;
}

.order-cancel-border {
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}
.word-ball {
  word-break: break-all;
}
.text-underline {
  text-decoration: underline;
}
.zoom-83 {
  zoom: 0.83;
}

.border-5 {
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

.app-position-table {
  display: block;
}

.app-position-table-mobile {
  display: none;
}

.notice-card-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  #root {
    .app-position-table {
      display: none;
    }

    .app-position-table-mobile {
      display: block;
    }

    .app-card-white {
      flex-direction: column;
    }
    .trade-number {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      margin-top: 16px;
      padding-top: 16px;
      width: 100%;
      margin-left: 0px;
      padding-left: 0px;
    }
    .app-trade-overview-card {
      margin-top: 24px;
      padding: 23px 16px;
      width: 100%;
      .app-pair-dropdown {
        width: 100%;
      }
      .app-pair-dropdown_arrow {
        position: absolute;
        right: 0;
      }
    }
    .detail-section-content {
      font-size: 12px;
    }
    .detail-section-title {
      zoom: 0.83;
    }
    .white-div {
      display: none;
    }
    .app-pair-dropdown_header-image {
      width: 32px;
      height: 32px;
    }
    .app-pair-dropdown_list {
      left: 0;
      width: 100%;
    }
    .header-img {
      width: 24px;
      height: 24px;
      content: url('../assets/mobile.svg');
    }
    .section-container {
      justify-content: space-between;
      width: 100%;
    }
    .mobile-height {
      /* position: fixed;
      overflow-y: scroll;
      z-index: 999;*/
      height:44px;
    }
    .mobile-margin {
      margin-top: 40px;
      padding: 0 16px;
    }
    .app-header {
      margin-top: 0;
      position: fixed;
      z-index: 30;
    }
    .mobile-container {
      width: 100%;
      justify-content: space-between;
      padding: 6px 16px;
      background: #fff;
      height:100%;
    }
    .mobile-expand {
      flex: 0;
    }
    .wkit-address-select__address {
      font-size: 12px !important;
      line-height: normal !important;
    }
    .wkit-connected-button {
      border-radius: 8px !important;
      border: 1px solid var(--line-l-ine, rgba(0, 0, 0, 0.15)) !important;
      background: var(--box, #fff) !important;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;
    }
    .is-mobile {
      display: flex;
    }
    .is-pc {
      display: none;
    }
    .app-trade-price-node > div {
      zoom: 0.83;
    }
    .app-trade-action {
      grid-row: 2;
      grid-column: 1;
    }
    .app-trade-main {
      grid-template-columns: minmax(300px, 1fr);
      justify-content:center ;
    }
    .app-table {
      font-size: 12px;
      overflow-x: auto;
    }

    .app-trade-main .app-table::-webkit-scrollbar {
      width: 6px;
      height: 4px;
    }

    .app-trade-main .app-table .app-table-cell {
      padding: 12px 4px;
      white-space: nowrap;
    }
    .app-trade-position-table .app-table-header-cell {
      font-size: 12px;
      padding: 0 2px;
    }
    /* .app-trade-position-table {
      zoom: 0.6;
    }
    .app-trade-orders-table {
      zoom: 0.8;
    }
    .app-trade-history-table {
      zoom: 0.7;
    } */
    /* .app-trade-position-table .app-table-row-header .app-table-header-cell:nth-child(5), .app-trade-position-table .app-table-row-header .app-table-header-cell:nth-child(6) {
      width: 0px;
      height: 0px;
      opacity: 0;
    }
    .app-trade-position-table .app-table-row .app-table-cell:nth-child(5), .app-trade-position-table .app-table-row .app-table-cell:nth-child(6) {
      width: 0px;
      height: 0px;
      opacity: 0;
    } */
    .app-trade-action-tab-item {
      font-size: 16px;
    }
    .app-crypto-dropdown-header,.app-token-input-input {
      font-size: 20px;
    }
    .app-footer-container {
      max-width: calc(100vw - 32px);
      padding: 16px 0;

      .media-link {
        width: 80%;
        justify-content: space-between;
      }

      .guide-link {
        span {
          display: none;
        }

        a:nth-child(2) {
          display: none;
        }
      }
    }

    .app-trade-price-node {
      min-width: auto;
    }

    .wkit-disconnect-button__container {
      bottom: -30px !important;
    }

    .wkit-disconnect-button__container .wkit-disconnect-button {
      padding: 4px 14px;
      font-size: 12px;
    }

    .notice-card {
      display: none;
    }

    .notice-card-mobile {
      display: flex;
    }
  }

  a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;

    &:visited, &:active {
      color: inherit;
    }
  }

  .app-pos-open-warning-content {
    a {
      color: rgba(0, 122, 240, 0.7);
    }
  }

  .ReactModal__Content {
    padding: 0 16px;

    .app-modal-card-header {
      margin-bottom: 16px;
    }

    .app-pos-open-token-info, .app-info-list, .app-pos-open-warning {
      margin-bottom: 12px;
    }

    .app-pos-open-token-info-item-value {
      margin-top: 12px;
    }

    .app-info-list-row {
      margin-bottom: 4px;
    }

    .app-pos-open-warning-title {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .ReactModal__Content .app-pos-adjust-card,
  .ReactModal__Content .app-pos-close-card,
  .ReactModal__Content .app-pos-open-card,
  .ReactModal__Content .app-swap-confirm-card,
  .ReactModal__Content .follow-card {
    width: calc(100vw - 32px);
    margin: 0 auto;
    max-height: calc(100vh - 64px);
    overflow-y: auto;
  }
}
