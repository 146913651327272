.app-pos-adjust-card {
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1), 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 430px;
  padding: 20px;
}

.app-pos-adjust-card-header {
  margin-bottom: 28px;
}

.app-pos-adjust-card-header-title {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 700;
}

.app-pos-adjust-card-header-title>img {
  margin-right: 15px;
}

.app-pos-adjust-card-header-close>img {
  margin: -20px;
  cursor: pointer;
}

.app-pos-adjust-action-tab {
  background: #F6F6F6;
  box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-bottom: 20px;
}

.app-pos-adjust-action-tab-item {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: 26px;
  cursor: pointer;
  border-radius: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 195px;
}

.app-pos-adjust-action-tab-item-active {
  color: #FFFFFF !important;
  border-radius: 10px;
  background-color: rgb(0, 122, 240);
}

.app-pos-adjust-detail {
  width: 100%;
}

.app-pos-adjust-detail-title {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.02);
  background-color: rgba(246, 246, 246, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px;
  /* margin-bottom: 20px; */
}

.app-pos-adjust-action-tab-secondary {
  margin-right: 20px;
  height: 80px;
}

.app-pos-adjust-action-tab-secondary>div {
  color: rgba(0, 0, 0, 0.5);
  font-size: 25px;
  font-weight: 700;
  line-height: 25px;
  width: 70px !important;
}

.app-pos-adjust-action-tab-collateral {
  font-size: 12px !important;
  line-height: 20px !important;
}

.app-pos-adjust-action-input {
  width: 100%;
  height: 80px;
}

.app-pos-adjust-detail-confirm-button {
  width: 100%;
  height: 60px;
}